import { Spin } from 'antd'
import { useEffect, useState, useMemo } from 'react'
import { utils } from '../../utils/utils'
import { DeviceServices } from '../../services/DeviceServices'
import BaseTable from '../../components/BaseTable'
import { useTranslation } from 'react-i18next'

const Statistics = () => {
  const [loading, setLoading] = useState(true)
  const { t } = useTranslation()
  const [listDeviceStatistics, setListDeviceStatistics] = useState([])

  const getData = async () => {
    try {
      const response = (await DeviceServices.getListDeviceStatistics()) || []
      setListDeviceStatistics(response || [])
    } catch (error) {
      utils.handleError(error)
    } finally {
      setLoading(false)
    }
  }

  const sortedDeviceStatistics = useMemo(() => {
    return [...listDeviceStatistics].sort((a, b) => {
      const typeA = a.type || ''
      const typeB = b.type || ''
      if (typeA === '' && typeB !== '') return 1
      if (typeA !== '' && typeB === '') return -1
      if (typeA === '' && typeB === '') return 0
      return typeA.localeCompare(typeB)
    })
  }, [listDeviceStatistics])

  const columnsDeviceStatistics = [
    {
      title: t('statistics.device_type'),
      dataIndex: 'type',
      key: 'type',
      width: 100,
      sorter: (a, b) => {
        if (a.type === '' && b.type !== '') return 1
        if (a.type !== '' && b.type === '') return -1
        return a.type.localeCompare(b.type)
      },
      render: (type) => (
        <span style={{ color: type === '' ? 'red' : 'inherit' }}>
          {type === '' ? 'undefined' : type}
        </span>
      )
    },
    {
      title: t('statistics.available'),
      dataIndex: 'available',
      key: 'available',
      width: 100,
      sorter: (a, b) => a.available - b.available
    },
    {
      title: t('statistics.using'),
      dataIndex: 'using',
      key: 'using',
      width: 100,
      sorter: (a, b) => a.using - b.using
    },
    {
      title: t('statistics.broken'),
      dataIndex: 'broken',
      key: 'broken',
      width: 100,
      sorter: (a, b) => a.broken - b.broken
    },
    {
      title: t('statistics.warranty'),
      dataIndex: 'warranty',
      key: 'warranty',
      width: 100,
      sorter: (a, b) => a.warranty - b.warranty
    },
    {
      title: t('statistics.deleted'),
      dataIndex: 'deleted',
      key: 'deleted',
      width: 100,
      sorter: (a, b) => a.deleted - b.deleted
    },
    {
      title: t('statistics.preparing'),
      dataIndex: 'preparing',
      key: 'preparing',
      width: 100,
      sorter: (a, b) => a.preparing - b.preparing
    },
    {
      title: t('statistics.total'),
      dataIndex: 'total',
      key: 'total',
      width: 100,
      sorter: (a, b) => a.total - b.total
    }
  ]

  useEffect(() => {
    getData()
  }, [])

  if (loading) {
    return (
      <div className="flex justify-center mt-4">
        <Spin></Spin>
      </div>
    )
  }
  return (
    <div>
      <BaseTable
        columns={columnsDeviceStatistics}
        dataSource={sortedDeviceStatistics}
      />
    </div>
  )
}
export default Statistics
